footer{
    margin-top: 80px; 
    background-color: #3c6382; 
    height: 90px; 
    width: 100%;
    position: relative;
}

.container_footer{
    display: flex; 
    justify-content: center; 
    align-items: center; 
}


.cntr_link_footer{
    display: flex; 
    justify-content: center; 
    align-items: center; 
}

.link_footer{
    color: aliceblue;
    margin: 20px 20px 0px 20px; 
    cursor: pointer;
}

.link_footer:hover{
    text-decoration: underline; 
    color: white
}

.container_copy{
    position: absolute;
    bottom: 0; left: 0; right: 0; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    font-size: 13px
}

.copyrigth{
    padding: 3px; 
    color: white
} 

@media screen and (max-width: 772px){
    footer{
        height: 190px;
    }

    .cntr_link_footer{
        flex-direction: column;
    }

    .container_copy{
        border-top: 1px solid white; 
    }
}