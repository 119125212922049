.container_operation{
    padding-top: 80px
}

.block{
    margin-top: 20px; 
    display: flex; 
}

.img_phone_fiche{
    float: left;
    height: 600px; 
    margin-right: 60px;
}

.content{
    /* margin-left: 50px */
}

/* .ul_liste_fiches_un, .ul_liste_fiches_deux{
    margin-right: 50px;
} */

.container_primary_ul{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-around
}

.container_ul{
    display: flex; 
    flex-direction: column
}

h2{
    color: #3c6382; 
    text-align: center; 
    margin-top: 25px; 
    margin-bottom: 30px
}

h3{
    font-size: 21px; 
    text-decoration: underline; 
    color: #3c6382
}

h4{
    font-size: 16px; 
    text-decoration: underline; 
    color: black
}

@media screen and (max-width: 991px){
    .img_phone_fiche{
        height: 460px; 
        margin-bottom: 20px
    }

    .ul_liste_fiches{
        margin-left: 290px;
    }

    .ul_liste_abonnement{
        margin-left: 0px
    }
}

@media screen and (max-width: 770px){
    .img_phone_fiche{
        height: 165px;
        margin-right: 30px;
    }

    /* .container_ul_3, .ul_liste_fiches_trois{
        display: none
    } */

    .ul_liste_fiches {
        margin-left: 200px;
    }
    
}

@media screen and (max-width: 510px){
    .img_phone_fiche{
        display: none;
    }
    
    .ul_liste_fiches {
        margin-left: 0px;
    }

}

@media screen and (max-width: 420px){
    .container_primary_ul{
        justify-content: flex-start
    }
}