.header{
    background-image: url('../../Shared/images/background-home.png'); 
    background-size: 100% 100%; 
    height: 100%; 
    width: 100%; 
}

.header{
    position: relative;
}

.header-elt{
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    bottom: 48%;
    left: 18%;
    padding: 20px;
    width: 550px; 
}

h1{
    /* color: #3c6382;  */
    color: white; 
}

.text-header{
    color: white; 
}

.container_image{
    display: flex; 
    justify-content: center; 
}

.logo_store{
    height: 50px;
    margin: 8px 20px 8px 20px
}

.header_img_phone{
    position: absolute;
    right: 0px; 
    height: 55%; 
    right: 15%;
    bottom: 25%;
}

.forme{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    position: absolute;
    bottom: 0;
    height: 0;
    width: 0;
    border-top: 100px solid transparent;
    z-index: 2;
    width: 100%;
    cursor: pointer; 
}

.arrow-down{
    height: 50px;
    position: relative;
    top: 50px;
}

.cntr_rond{
    margin-top: 80px; 
    /* margin-bottom: 70px;  */
    width: 100%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
}

.hr-left{
    height: 1px; 
    border: 0.2px solid #d1d1d1; 
    width: 25%
}   

.rond{
    height: 30px; 
    width: 30px;
    background-color: #3c6382; 
    border-radius: 50%; 
    border: 5px solid white; 
}   

.hr-rigth{
    height: 1px; 
    border: 0.2px solid #d1d1d1; 
    width: 25%; 
}


@media screen and (max-width: 1320px){
    .header-elt{
        left: 10%; 

    }
}

@media screen and (max-width: 1090px){
    .header{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-elt{
        background-color: rgba(0,0,0,0.4);
        position: initial;
    }

    .header_img_phone{
        display: none; 
    }
}

@media screen and (max-width: 800px){
    .header {
        background-size: 160% 110%;
        background-repeat: no-repeat;
        background-position-x: 40%;
    }

    .container_image{
        flex-wrap: wrap
    }
}

@media screen and (max-width: 800px){
    .header {
        background-size: 300% 120%;
        background-repeat: no-repeat;
        background-position-x: 30%
    }
}

@media screen and (max-width: 400px){
    .header-elt {
        position: relative;
        top: 0px;
        left: 0px;
        right: 0px; 
        margin-top: 50px
    }

    .hr-left, .hr-rigth{
        width: 35%
    }
}