body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*container_primary**/

.container_primary{
  padding-top: 40px
}

/** Footer **/ 

.container_footer{
  bottom: 0; left: 0; right: 0;
  width: 100%
}

.container_form_sign{
  background-color: rgba(255,255,255,0.85); 
  padding: 40px; 
  margin-top: 40px
}

/** Container signin & signup **/

.container_sign{
  padding-top: 80px; 
  display: -webkit-flex; 
  display: flex; 
  -webkit-flex-direction: column; 
          flex-direction: column; 
  -webkit-justify-content: space-between; 
          justify-content: space-between;  
  position: relative;
  background-image: url(/static/media/background-home.0832dc0c.jpg); 
  min-height: 100%; 
  background-size: 100% 100%
}

/** Container user **/
.container_user{
  padding-top: 80px;
  display: -webkit-flex;
  display: flex; 
  -webkit-flex-direction: column; 
          flex-direction: column; 
  -webkit-justify-content: space-between; 
          justify-content: space-between;  
  position: relative;
  background-color: #e5e5e5; 
  min-height: 100%; 
}

/**Chargement**/

.loader{
  height: 80px; 
  position: relative; 
}

.loader_button{
  height: 30px
}

button{
  width: 170px;
}

/**Mentions légales**/

.header_title_mention{
  position: relative;
  background-image: url(/static/media/background-home.1fc596c9.png); 
  width: 100%; 
  height: 200px; 
  background-repeat: no-repeat;
  background-position-y: 35%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.voile_image_mention{
  background-color: rgba(0,0,0,0.5); 
  width: 100%; 
  height: 100%; 
  position: absolute; 
  z-index: 0
}

.container_mention{
  padding-top: 90px; 
  height: 100%
}

.title_mention{
  z-index: 2
}

.h2_mention{
  text-align: left; 
  font-size: 21px; 
  margin-bottom: 10px; 
  text-decoration: underline
}

/** button **/

.cntr_button{
    margin-top: 25px; 
    display: -webkit-flex; 
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center
}

button{
  color: #3c6382
}

/** ADMINISTATION **/
.inputSearch{
  width: 320px; 
  margin: 25px 0px 25px 0px; 
  /* border-radius: 10px;  */
  border: 2px solid #3c6382; 
  padding: 5px
}

/** WINDOW MESSAGE **/
.window-other{
  position: absolute; 
  bottom: 0; right: 0; 
  height: 100%; 
  width: 100%; 
  top: 0; left: 0; 
  background: rgba(0, 0, 0, 0.5); 
  display: -webkit-flex; 
  display: flex; 
  -webkit-justify-content: center; 
          justify-content: center; 
  -webkit-align-items: center; 
          align-items: center; 
  z-index: 9999
}

.content-window-other{
  background-color: white; 
  max-width: 600px; 
  width: 100%
}

.img-close{
  position: absolute; 
  right: 30px; 
  top: 100px; 
  cursor: pointer; 
  background: white; 
  border-radius: 100%
}

@media screen and (max-width: 930px){
  .title_mention{
    font-size: 25px; 
    padding: 10px; 
    text-align: center
  }
}

html, body, #root, .App, .primary {
  height: 100%; 
  width: 100%
}

body{
  min-width: 900px;
}

a:hover{
  text-decoration: none;
}
.header{
    background-image: url(/static/media/background-home.1fc596c9.png); 
    background-size: 100% 100%; 
    height: 100%; 
    width: 100%; 
}

.header{
    position: relative;
}

.header-elt{
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    bottom: 48%;
    left: 18%;
    padding: 20px;
    width: 550px; 
}

h1{
    /* color: #3c6382;  */
    color: white; 
}

.text-header{
    color: white; 
}

.container_image{
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
}

.logo_store{
    height: 50px;
    margin: 8px 20px 8px 20px
}

.header_img_phone{
    position: absolute;
    right: 0px; 
    height: 55%; 
    right: 15%;
    bottom: 25%;
}

.forme{
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center; 
    position: absolute;
    bottom: 0;
    height: 0;
    width: 0;
    border-top: 100px solid transparent;
    z-index: 2;
    width: 100%;
    cursor: pointer; 
}

.arrow-down{
    height: 50px;
    position: relative;
    top: 50px;
}

.cntr_rond{
    margin-top: 80px; 
    /* margin-bottom: 70px;  */
    width: 100%; 
    display: -webkit-flex; 
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center; 
}

.hr-left{
    height: 1px; 
    border: 0.2px solid #d1d1d1; 
    width: 25%
}   

.rond{
    height: 30px; 
    width: 30px;
    background-color: #3c6382; 
    border-radius: 50%; 
    border: 5px solid white; 
}   

.hr-rigth{
    height: 1px; 
    border: 0.2px solid #d1d1d1; 
    width: 25%; 
}


@media screen and (max-width: 1320px){
    .header-elt{
        left: 10%; 

    }
}

@media screen and (max-width: 1090px){
    .header{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

    .header-elt{
        background-color: rgba(0,0,0,0.4);
        position: static;
        position: initial;
    }

    .header_img_phone{
        display: none; 
    }
}

@media screen and (max-width: 800px){
    .header {
        background-size: 160% 110%;
        background-repeat: no-repeat;
        background-position-x: 40%;
    }

    .container_image{
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap
    }
}

@media screen and (max-width: 800px){
    .header {
        background-size: 300% 120%;
        background-repeat: no-repeat;
        background-position-x: 30%
    }
}

@media screen and (max-width: 400px){
    .header-elt {
        position: relative;
        top: 0px;
        left: 0px;
        right: 0px; 
        margin-top: 50px
    }

    .hr-left, .hr-rigth{
        width: 35%
    }
}
.container_operation{
    padding-top: 80px
}

.block{
    margin-top: 20px; 
    display: -webkit-flex; 
    display: flex; 
}

.img_phone_fiche{
    float: left;
    height: 600px; 
    margin-right: 60px;
}

.content{
    /* margin-left: 50px */
}

/* .ul_liste_fiches_un, .ul_liste_fiches_deux{
    margin-right: 50px;
} */

.container_primary_ul{
    display: -webkit-flex;
    display: flex; 
    -webkit-flex-wrap: wrap; 
            flex-wrap: wrap; 
    -webkit-justify-content: space-around; 
            justify-content: space-around
}

.container_ul{
    display: -webkit-flex;
    display: flex; 
    -webkit-flex-direction: column; 
            flex-direction: column
}

h2{
    color: #3c6382; 
    text-align: center; 
    margin-top: 25px; 
    margin-bottom: 30px
}

h3{
    font-size: 21px; 
    text-decoration: underline; 
    color: #3c6382
}

h4{
    font-size: 16px; 
    text-decoration: underline; 
    color: black
}

@media screen and (max-width: 991px){
    .img_phone_fiche{
        height: 460px; 
        margin-bottom: 20px
    }

    .ul_liste_fiches{
        margin-left: 290px;
    }

    .ul_liste_abonnement{
        margin-left: 0px
    }
}

@media screen and (max-width: 770px){
    .img_phone_fiche{
        height: 165px;
        margin-right: 30px;
    }

    /* .container_ul_3, .ul_liste_fiches_trois{
        display: none
    } */

    .ul_liste_fiches {
        margin-left: 200px;
    }
    
}

@media screen and (max-width: 510px){
    .img_phone_fiche{
        display: none;
    }
    
    .ul_liste_fiches {
        margin-left: 0px;
    }

}

@media screen and (max-width: 420px){
    .container_primary_ul{
        -webkit-justify-content: flex-start;
                justify-content: flex-start
    }
}
.container_action{
    padding-top: 80px
}

.block_action{
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-flex-direction: column; 
            flex-direction: column; 
    -webkit-align-items: center; 
            align-items: center
}

.cntr_block{
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: space-between; 
            justify-content: space-between; 
    -webkit-align-items: center; 
            align-items: center; 
    width: 100%; 
    margin-top: 50px
}

.cntr_elt{
    display: -webkit-flex;
    display: flex; 
    -webkit-flex-direction: column; 
            flex-direction: column; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center; 
    margin: 10px 25px 10px 25px
}

.img_action{
    height: 90px; 
    width: 90px
}

.p_action{
    margin-top: 15px; 
    color: #3c6382; 
}

.arrow_rigth{
    height: 50px
}

@media screen and (max-width: 580px){
    .arrow_rigth{
        display: none; 
    }

    .cntr_block{
        -webkit-justify-content: center;
                justify-content: center; 
        -webkit-flex-wrap: wrap; 
                flex-wrap: wrap
    }
}
.loader_button{
    height: 36px;
}

.text-error-form{
    font-size: 12px;
}


.background_contact{
    padding-top: 80px
}

.form{
    display: -webkit-flex;
    display: flex; 
    -webkit-flex-direction: column; 
            flex-direction: column; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-align-items: initial; 
            align-items: initial
}

.btn{
    background-color: #3c6382; 
    color: white
}

.btn:hover{
    background-color: #3c6382; 
    color: white
}

.sub_container_form{
    display: -webkit-flex;
    display: flex; 
}

.cntr_elt_form{
    width: 100%; 
    margin: 5px 10px 5px 10px
}


@media screen and (max-width: 420px){
    .sub_container_form{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
footer{
    margin-top: 80px; 
    background-color: #3c6382; 
    height: 90px; 
    width: 100%;
    position: relative;
}

.container_footer{
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center; 
}


.cntr_link_footer{
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center; 
}

.link_footer{
    color: aliceblue;
    margin: 20px 20px 0px 20px; 
    cursor: pointer;
}

.link_footer:hover{
    text-decoration: underline; 
    color: white
}

.container_copy{
    position: absolute;
    bottom: 0; left: 0; right: 0; 
    display: -webkit-flex; 
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center; 
    font-size: 13px
}

.copyrigth{
    padding: 3px; 
    color: white
} 

@media screen and (max-width: 772px){
    footer{
        height: 190px;
    }

    .cntr_link_footer{
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .container_copy{
        border-top: 1px solid white; 
    }
}
header{
    position: fixed; 
    top: 0; left: 0; right: 0; 
    z-index: 99999; 
    height: 80px; 
    display: -webkit-flex; 
    display: flex; 
    -webkit-align-items: center; 
            align-items: center; 
    background: white; 
    border-bottom: 1px solid #d1d1d1
}

.container_header{
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: space-between; 
            justify-content: space-between; 
    -webkit-align-items: center; 
            align-items: center
}

.logo{
    height: 60px; 
    margin-left: 15px; 
}

.container_link{
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center
}

.a_header{
    margin: 25px; 
    color: #3c6382
}

.a_header:hover{
    text-decoration: none; 
    color: #3c6382
}

@media screen and (max-width: 570px){
    .logo{
        height: 40px;
    }

    .a_header{
        margin: 5px; 
    }

    .container_header{
        -webkit-flex-direction: column;
                flex-direction: column; 
    }
}

/** Profil **/

.container_form_profil{
    padding: 40px
}

.card{
    margin-top: 40px
}

.card-tite{
    color: #3c6382
}

/** Facture **/

.container_grid{
    background: white; 
    padding: 20px; 
    height: 100%; 
    min-height: 350px
}

.icon-grid{
    width: 48px; 
}

/** Preview PDF **/

.iframe_preview_pdf{
    height: 100%; 
    width: 100%; 
    padding-bottom: 170px
}

.footer_preview_pdf{
    box-shadow: 0px 1px 21px -2px rgba(0,0,0,0.75);
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center; 
    background: white; 
    height: 90px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
.container_primary_admin{
    padding-top: 80px
}

.container_home{
    height: 100%; 
    max-height: 100%;
}

.MuiTabs-flexContainer-29{
    width: 100%; 
    display: -webkit-flex; 
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center
}

.container_menu_bottom{
    display: -webkit-flex;
    display: flex;  
    -webkit-justify-content: center;  
            justify-content: center; 
    position: absolute; 
    left: 0; bottom: 0; right: 0; 
}

.container_cache_cms{
    -webkit-justify-content: center;
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center; 
    position: absolute;  
    top: 0;  
    bottom: 0; 
    left: 0; 
    right: 0; 
    background-color: rgba(0, 0, 0, 0.3)
}
