.container_primary_admin{
    padding-top: 80px
}

.container_home{
    height: 100%; 
    max-height: 100%;
}

.MuiTabs-flexContainer-29{
    width: 100%; 
    display: flex; 
    justify-content: center
}

.container_menu_bottom{
    display: flex;  
    justify-content: center; 
    position: absolute; 
    left: 0; bottom: 0; right: 0; 
}

.container_cache_cms{
    justify-content: center; 
    align-items: center; 
    position: absolute;  
    top: 0;  
    bottom: 0; 
    left: 0; 
    right: 0; 
    background-color: rgba(0, 0, 0, 0.3)
}