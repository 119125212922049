header{
    position: fixed; 
    top: 0; left: 0; right: 0; 
    z-index: 99999; 
    height: 80px; 
    display: flex; 
    align-items: center; 
    background: white; 
    border-bottom: 1px solid #d1d1d1
}

.container_header{
    display: flex; 
    justify-content: space-between; 
    align-items: center
}

.logo{
    height: 60px; 
    margin-left: 15px; 
}

.container_link{
    display: flex; 
    justify-content: center; 
    align-items: center
}

.a_header{
    margin: 25px; 
    color: #3c6382
}

.a_header:hover{
    text-decoration: none; 
    color: #3c6382
}

@media screen and (max-width: 570px){
    .logo{
        height: 40px;
    }

    .a_header{
        margin: 5px; 
    }

    .container_header{
        flex-direction: column; 
    }
}