.container_action{
    padding-top: 80px
}

.block_action{
    display: flex; 
    justify-content: center; 
    flex-direction: column; 
    align-items: center
}

.cntr_block{
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    width: 100%; 
    margin-top: 50px
}

.cntr_elt{
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    margin: 10px 25px 10px 25px
}

.img_action{
    height: 90px; 
    width: 90px
}

.p_action{
    margin-top: 15px; 
    color: #3c6382; 
}

.arrow_rigth{
    height: 50px
}

@media screen and (max-width: 580px){
    .arrow_rigth{
        display: none; 
    }

    .cntr_block{
        justify-content: center; 
        flex-wrap: wrap
    }
}