html, body, #root, .App, .primary {
  height: 100%; 
  width: 100%
}

body{
  min-width: 900px;
}

a:hover{
  text-decoration: none;
}