body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*container_primary**/

.container_primary{
  padding-top: 40px
}

/** Footer **/ 

.container_footer{
  bottom: 0; left: 0; right: 0;
  width: 100%
}

.container_form_sign{
  background-color: rgba(255,255,255,0.85); 
  padding: 40px; 
  margin-top: 40px
}

/** Container signin & signup **/

.container_sign{
  padding-top: 80px; 
  display: flex; 
  flex-direction: column; 
  justify-content: space-between;  
  position: relative;
  background-image: url('./Shared/images/background-home.jpg'); 
  min-height: 100%; 
  background-size: 100% 100%
}

/** Container user **/
.container_user{
  padding-top: 80px;
  display: flex; 
  flex-direction: column; 
  justify-content: space-between;  
  position: relative;
  background-color: #e5e5e5; 
  min-height: 100%; 
}

/**Chargement**/

.loader{
  height: 80px; 
  position: relative; 
}

.loader_button{
  height: 30px
}

button{
  width: 170px;
}

/**Mentions légales**/

.header_title_mention{
  position: relative;
  background-image: url('./Shared/images/background-home.png'); 
  width: 100%; 
  height: 200px; 
  background-repeat: no-repeat;
  background-position-y: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.voile_image_mention{
  background-color: rgba(0,0,0,0.5); 
  width: 100%; 
  height: 100%; 
  position: absolute; 
  z-index: 0
}

.container_mention{
  padding-top: 90px; 
  height: 100%
}

.title_mention{
  z-index: 2
}

.h2_mention{
  text-align: left; 
  font-size: 21px; 
  margin-bottom: 10px; 
  text-decoration: underline
}

/** button **/

.cntr_button{
    margin-top: 25px; 
    display: flex; 
    justify-content: center
}

button{
  color: #3c6382
}

/** ADMINISTATION **/
.inputSearch{
  width: 320px; 
  margin: 25px 0px 25px 0px; 
  /* border-radius: 10px;  */
  border: 2px solid #3c6382; 
  padding: 5px
}

/** WINDOW MESSAGE **/
.window-other{
  position: absolute; 
  bottom: 0; right: 0; 
  height: 100%; 
  width: 100%; 
  top: 0; left: 0; 
  background: rgba(0, 0, 0, 0.5); 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  z-index: 9999
}

.content-window-other{
  background-color: white; 
  max-width: 600px; 
  width: 100%
}

.img-close{
  position: absolute; 
  right: 30px; 
  top: 100px; 
  cursor: pointer; 
  background: white; 
  border-radius: 100%
}

@media screen and (max-width: 930px){
  .title_mention{
    font-size: 25px; 
    padding: 10px; 
    text-align: center
  }
}
