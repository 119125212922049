
/** Profil **/

.container_form_profil{
    padding: 40px
}

.card{
    margin-top: 40px
}

.card-tite{
    color: #3c6382
}

/** Facture **/

.container_grid{
    background: white; 
    padding: 20px; 
    height: 100%; 
    min-height: 350px
}

.icon-grid{
    width: 48px; 
}

/** Preview PDF **/

.iframe_preview_pdf{
    height: 100%; 
    width: 100%; 
    padding-bottom: 170px
}

.footer_preview_pdf{
    -webkit-box-shadow: 0px 1px 21px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 21px -2px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 21px -2px rgba(0,0,0,0.75);
    display: flex; 
    justify-content: center; 
    align-items: center; 
    background: white; 
    height: 90px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}