.background_contact{
    padding-top: 80px
}

.form{
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: initial
}

.btn{
    background-color: #3c6382; 
    color: white
}

.btn:hover{
    background-color: #3c6382; 
    color: white
}

.sub_container_form{
    display: flex; 
}

.cntr_elt_form{
    width: 100%; 
    margin: 5px 10px 5px 10px
}


@media screen and (max-width: 420px){
    .sub_container_form{
        flex-direction: column;
    }
}